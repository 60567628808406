<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div class="col s12">
        <v-card>
          <v-card-title>
            <span class="headline">{{ thisuser.name }} ({{ loadedUser.email }}) {{ userid }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="thisuser.name"
                    label="Full Name"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="thisuser.phone"
                    label="Phone #"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="thisuser.companyId"
                    :items="companies"
                    item-text="name"
                    item-value="id"
                    label="Select Company"
                    outlined
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="thisuser.usertype"
                    :items="usertypes"
                    item-text="name"
                    item-value="id"
                    label="User type"
                    outlined
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="thisuser.preferredloanofficer"
                    :items="loanofficers"
                    item-text="name"
                    item-value="id"
                    label="Preferred Loan Officer"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn
              color="blue darken-1"
              text
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>

        <h3>Notes</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Date
                </th>
                <th class="text-left">
                  User
                </th>
                <th class="text-left">
                  Note
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in thisuser.Notes"
                :key="item.created"
              >
                <td>{{ item.created | formatDate }}</td>
                <td>{{ userNameById(item.User) }}</td>
                <td>{{ item.Note }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3">
                  <v-form
                    ref="newnoteform"
                    v-model="newnotevalid"
                    lazy-validation
                    @submit.prevent="savenote"
                  >
                    <v-text-field
                      v-model="newnote"
                      label="New Note"
                      required
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      type="submit"
                    >
                      Add Note
                    </v-btn>
                  </v-form>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        
        <h3>Deals</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  ID
                </th>
                <th class="text-left">
                  NickName
                </th>
                <th class="text-left">
                  Address
                </th>
                <th class="text-left">
                  RequestedLoanAmount
                </th>
                <th class="text-left">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in deals"
                :key="item.id"
              >
                <td><a :href="'/portal/deals/'+item.id">{{ item.id }}</a></td>
                <td>{{ item.NickName }}</td>
                <td>{{ item.Address.Line1 }}</td>
                <td>{{ item.RequestedAmount }}</td>
                <td>{{ item.Status[0].StatusName }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h3>Deal Docs</h3>
        <v-simple-table v-if="docsLoaded">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Path
                </th>
                <th class="text-left">
                  File
                </th>
                <th class="text-left">
                  Status TimeStamp
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(doc, idx) in acceptedDealDocs"
                :key="idx"
              >
                <td>{{ doc.conditionname }}</td>
                <td><a :href="doc.url" target="_blank">{{ doc.DocFileName }}</a></td>
                <td>{{ doc.StatusTimeStamp | formatDate }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      
      <v-snackbar
        v-model="snackbar"
      >
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </base-section>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Users } from '@/database/users'
  // import { Deals } from '@/database/deal'
  import { Companies } from '@/database/companies'
  import { System } from '@/database/system'
  import { FirebaseResults } from '@/database'
  import { Storage } from '@/database/config/firebase'

  const storageRef = Storage.ref()
  const getDownloadURL = async (path, filename) => { 
    const objectRef = await storageRef.child(path + filename)
    var returnURL = await objectRef.getDownloadURL().then((url) => {
      return url
    })
    return returnURL
  }

  export default {
    name: 'SectionMap',
    data: () => ({
      userid: '',
      thisuser: {},
      loadedUser: {},
      users: [],
      deals: [],
      usertypes: [],
      loanofficers: [],
      companies: [],
      acceptedDealDocs: [],
      docsLoaded: false,
      snackbar: false,
      snackbarMessage: '',
      newnotevalid: true,
      newnote: '',
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      }),
    },
    async mounted () {
      this.userid = this.$route.params.userid
      await this.initialize()
    },
    methods: {
      async initialize () {
        this.fetchSystem()
        this.fetchLoanOfficers()
        this.fetchCompanies()
        Users.findOne(this.userid).then(doc => {
          this.thisuser = doc.data()
          this.thisuser.id = doc.id
          // By User Type, Load the List of Deals
          switch (this.thisuser.usertype.toLowerCase()){
            case 'borrower':
              console.log(this.thisuser.id)
              Deals.findAllMine(this.thisuser.id)
              .get()
              .then(querySnapshot => {
                const results = FirebaseResults.map(querySnapshot)
                console.log(results.Status)
                results.Status = this.sortDealStatus(results.Status)
                this.deals = results
                
                this.fetchDocs()
              })
              break
            case 'loan officer':
              Deals.findAllByLO(this.thisuser.id)
              .get()
              .then(querySnapshot => {
                  const results = FirebaseResults.map(querySnapshot)
                  results.Status = this.sortDealStatus(results.Status)
                  this.deals = results
                  this.fetchDocs()
              })
              break
            case 'processor':
              Deals.findAllByProcessor(this.thisuser.id)
              .get()
              .then(querySnapshot => {
                  const results = FirebaseResults.map(querySnapshot)
                  results.Status = this.sortDealStatus(results.Status)
                  this.deals = results
                  this.fetchDocs()
              })
              break
          }
          this.loadedUser = this.thisuser
        })
        Users.findAllActive().onSnapshot(docs => {
          const results = FirebaseResults.map(docs)
          this.users = results.map(function(item){
            return { id: item.id, name: item.name }
          })
        })
      },
      fetchSystem () {
        System.findOne('usertypes').then(doc => {
          const results = doc.data().data
          this.usertypes = results.map(function(item){
            return { id: item.name, name: item.name }
          })
        })
      },
      fetchLoanOfficers () {
        Users.findAllLoanOfficers().onSnapshot(docs => {
          const results = FirebaseResults.map(docs)
          this.loanofficers = results.map(function(item){
            return { id: item.id, name: item.name }
          })
        })
      },
      fetchCompanies () {
        Companies.findAll().onSnapshot(docs => {
          const results = FirebaseResults.map(docs)
          this.companies = results.map(function(item){
            return { id: item.id, name: item.companyName }
          })
        })
      },
      fetchDocs () {
        this.loadDocsFromDeal().then(res => {
          this.acceptedDealDocs.forEach(doc => {
            doc.returnURL.then((url) => { doc.url = url })
          })
          this.docsLoaded = true
        })
      },
      loadDocsFromDeal () {
        var docContainer = []
        const promisedEvents = []
        // _.forEach(this.deals, function(deal){
        this.deals.forEach(deal => {
          deal.Conditions.forEach(condition => {
          // _.forEach(deal.Conditions, function(condition){
            if (condition.Docs){
              var acceptedDocs = condition.Docs.filter(function(item) { return item.Status === 'Accepted' })
              acceptedDocs.forEach(thisDoc => {
              // _.forEach(acceptedDocs, function (thisDoc) {
                thisDoc.conditionname = condition.name
                thisDoc.returnURL = getDownloadURL(thisDoc.DocPath, thisDoc.DocFileName).then(url => {
                  return Promise.resolve(url)
                })
                promisedEvents.push(thisDoc.returnURL)
                
                // thisDoc.url = ''
                docContainer.push(thisDoc)
              })
            }
          })
        })

        this.acceptedDealDocs = [...docContainer]
        return Promise.all(promisedEvents)
      },
      runtest () {
        console.log(this.acceptedDealDocs)
      },
      savedone () {
        this.$router.push('/portal/users')
      },
      save () {
        Users.update(this.userid, this.thisuser)
          .then(() => {
            this.snackbarMessage = 'User Saved.'
            this.snackbar = true
            setTimeout(this.savedone, 2500)
          })
          .catch(error => {
            console.log(error)
          })
      },
      savenote () {
        if (!this.thisuser.Notes) {
          this.thisuser.Notes = []
        }
        const newNoteObj = {
          User: this.user.id,
          Note: this.newnote,
          created: Date(),
        }
        this.thisuser.Notes = [...this.thisuser.Notes, newNoteObj]
        Users.update(this.userid, this.thisuser).then(results => {
          this.snackbarMessage = 'Note Added.'
          this.snackbar = true
          this.newnote = ''
        })
      },
      userNameById (matchUID) {
        var match = this.users.filter(item => item.id === matchUID)
          if (match.length > 0){
          return match[0].name
        } else {
          return 'Unable to Find User'
        }
      },
      showLatestStatus (arr) {
        return arr.sort((a, b) => (a.StatusDate < b.StatusDate) ? 1 : -1)[0].StatusName
      },
      sortDealStatus (arr) {
        if (arr) {
          return arr.sort((a, b) => (a.StatusDate < b.StatusDate) ? 1 : -1)
        } else {
          return arr
        }
      },
    },
  }
</script>
