import { FirebaseCompanies } from './index'

const companyDb = () => {
  const add = (companyInfo) => {
    return FirebaseCompanies.add(companyInfo)
  }

  const update = (companyId, companyInfo) => {
    return FirebaseCompanies.doc(companyId).update(companyInfo)
  }

  const remove = (companyId) => {
    return FirebaseCompanies.doc(companyId).delete()
  }

  const findOne = (companyId) => {
    return FirebaseCompanies.doc(companyId).get()
  }

  const findAll = () => {
    return FirebaseCompanies.orderBy('companyName')
  }

  const findAllLenders = () => {
    return FirebaseCompanies.where('companyType', 'in', ['Institutional Lender', 'Private Lender']).orderBy('companyName')
  }
  // const findLenderByFilter = (loantypes, propertytypes, loanamount) => {
  //   if (!loantypes && !propertytypes && !loanamount){ return findAllLenders() }
  //   let query = FirebaseCompanies.where('companyType', 'in', ['Institutional Lender', 'Private Lender'])
  //   if (loantypes){
  //     query = query.where('loantypes', 'in', loantypes)
  //   }
  //   if (propertytypes){
  //     query = query.where('propertytypes', 'in', propertytypes)
  //   }
  //   if (loanamount){
  //     query = query.where('loanrangelow', '<=', loanamount)
  //     query = query.where('loanrangehigh', '>=', loanamount)
  //   }
  //   return query.orderBy('companyName')
  // }
  const findAllBorrowers = () => {
    return FirebaseCompanies.where('companyType', 'in', ['Borrower']).orderBy(
      'companyName',
    )
  }

  return Object.freeze({
    add,
    update,
    findOne,
    findAll,
    findAllLenders,
    // findLenderByFilter,
    findAllBorrowers,
    remove,
  })
}

const Companies = companyDb()

export { Companies }
