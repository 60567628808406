import { FirebaseUsers } from './index'

const usersDb = () => {
  const add = _userInfo => {
    return false
    // return FirebaseEmployees.add(userInfo)
  }

  const update = (userId, userInfo) => {
    delete userInfo.email
    delete userInfo.password
    return FirebaseUsers.doc(userId).update(userInfo)
  }

  const findOne = userId => {
    return FirebaseUsers.doc(userId).get()
  }

  const findByUserId = userId => {
    return FirebaseUsers.where('userId', '==', userId).get()
  }

  // const findAll = ({ usertype, active = true }) => {
  //   let query = FirebaseUsers.where('active', '==', active)
  //   if (usertype) {
  //     query = query.where('usertype', '==', usertype)
  //   }

  //   return query.orderBy('name')
  // }
  const findAll = () => {
    const query = FirebaseUsers
    // .where('active', '==', active)
    // if (usertype) {
    //   query = query.where('usertype', '==', usertype)
    // }

    return query.orderBy('name')
  }
  const findAllLoanOfficers = () => {
    let query = FirebaseUsers.where('active', '==', true)
    query = query.where('usertype', 'in', ['Loan Officer', 'Manager'])
    return query.orderBy('name')
  }
  const findAllProcessors = () => {
    let query = FirebaseUsers.where('active', '==', true)
    query = query.where('usertype', 'in', ['Processor'])
    return query.orderBy('name')
  }
  const findAllActive = () => {
    const query = FirebaseUsers.where('active', '==', true).orderBy('name')
    return query
  }

  const toggleStatus = user => {
    if (!user.active) {
      return unarchive(user.id)
    } else {
      return archive(user.id)
    }
  }

  // TODO: Move to server function to check permission
  const archive = userId => {
    return FirebaseUsers.doc(userId).set({ active: false }, { merge: true })
  }
  // TODO: Move to server function to check permissions
  const unarchive = userId => {
    return FirebaseUsers.doc(userId).set({ active: true }, { merge: true })
  }

  return Object.freeze({
    add,
    archive,
    update,
    findOne,
    findAll,
    findByUserId,
    findAllLoanOfficers,
    findAllProcessors,
    findAllActive,
    toggleStatus,
    unarchive,
  })
}

const Users = usersDb()

export { Users }
