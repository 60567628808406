import { FirebaseSystem } from './index'

const systemDb = () => {
  const add = (systemInfo) => {
    return FirebaseSystem.add(systemInfo)
  }

  const update = (docId, systemInfo) => {
    return FirebaseSystem.doc(docId).update(systemInfo)
  }

  const remove = (docId) => {
    return FirebaseSystem.doc(docId).delete()
  }

  const findOne = docId => {
    return FirebaseSystem.doc(docId).get()
  }

  // const findAll = (typename) => {
  //   return FirebaseSystem.where('systemtype', '==', typename).orderBy('name')
  // }
  const findAll = () => {
    return FirebaseSystem.orderBy('id')
  }

  return Object.freeze({
    add,
    update,
    findOne,
    findAll,
    remove,
  })
}

const System = systemDb()

export { System }
